body{
    margin: 0;
    background-image: url(../img/background.png);
    background-size: 100% 130%;
}

.body-website{
    display: flex;
    flex-direction: column;    
}

.logo{
    width: 300px;
    height: 70px;
    margin-bottom: 123px;

    &__mini{
        width: 138px;
        height: 32px;
    }
}